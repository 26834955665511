import React from "react";
//change
import { useParams } from "react-router-dom";
import Container from "../Content_Container/container.js";
import NavContainer from "../NavContainer/navContainer";
import "./art.css";
import images from "../../art-images.json";
import ImageCard from "../ImageCard/imageCard.js";

const Art = () => {
  let { topicPath } = useParams();

  let filterImages;

  if (topicPath) {
    filterImages = images.filter((i) => i.name === topicPath);
  } else {
    filterImages = images;
  }

  return (
    <div>
      <NavContainer />
      <Container>
        {filterImages.map((image) => (
          <a role="button" href={`/artproject${image.name}`} key={image.id}>
            <ImageCard
              key={image.id}
              className="image-card"
              id={image.id}
              name={image.name}
              image={image.image}
              category={image.category}
            />
          </a>
        ))}
      </Container>
    </div>
  );
};

export default Art;
