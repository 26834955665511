import React from "react";
import { useParams } from "react-router-dom";
import ImageCard from "../ImageCard/imageCard.js";
import Container from "../Content_Container/container.js";
import images from "../../images.json";

const DesignMain = () => {
  let { topicPath } = useParams();

  let filterImages;

  if (topicPath) {
    filterImages = images.filter((i) => i.name === topicPath);
  } else {
    filterImages = images;
  }

  return (
    <Container>
      {filterImages.map((image) => (
        <a role="button" href={`/project${image.name}`} key={image.id}>
          <ImageCard
            key={image.id}
            className="image-card"
            id={image.id}
            name={image.name}
            image={image.image}
            category={image.category}
          />
        </a>
      ))}
    </Container>
  );
};
export default DesignMain;
