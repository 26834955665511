import React from "react";
import styled from "styled-components";
import NavContainer from "../NavContainer/navContainer";
import Container from "../Content_Container/container.js";
import "../css/designVerticalLeft.css";
import projects from "../../images.json";

const ImgOne = styled.img`
  width: 636px;
  height: 468px;

  @media (min-width: 275px) {
    width: 350px;
    height: 258px;
    margin-bottom: 5px;
  }

  @media (min-width: 992px) {
    width: 487px;
    height: 359px;
    margin-right: 2.5px;
  }

  @media (min-width: 1200px) {
    width: 567px;
    height: 417px;
    margin-right: 5px;
  }
  @media (min-width: 2000px) {
    width: 636px;
    height: 468px;
    max-width: 636px;
    margin-right: 5px;
  }
`;

const ImgTwo = styled.img`
  width: 636px;
  height: 468px;

  @media (min-width: 275px) {
    width: 350px;
    height: 258px;
    margin-bottom: 5px;
  }

  @media (min-width: 992px) {
    width: 487px;
    height: 359px;
    margin-left: 2.5px;
  }

  @media (min-width: 1200px) {
    width: 567px;
    height: 417px;
    margin-left: 5px;
  }
  @media (min-width: 2000px) {
    width: 636px;
    height: 468px;
    max-width: 636px;
    margin-left: 5px;
  }
`;

const ImgThree = styled.img`
  width: 1282px;
  height: 468px;
  margin-bottom: 10px;

  @media (min-width: 275px) {
    width: 350px;
    height: 128px;
  }

  @media (min-width: 768px) {
    width: 700px;
    height: 255px;
  }
  @media (min-width: 992px) {
    width: 979px;
    height: 357px;
    margin-top: 5px;
  }

  @media (min-width: 1200px) {
    width: 1145px;
    height: 418px;
    margin-top: 10px;
  }
  @media (min-width: 2000px) {
    width: 1282px;
    height: 468px;
    max-width: 1282px;
    margin-top: 10px;
  }
`;

const DesignVertLeft = (props) => {
  const name = props.match.params.name;

  //looking at the url to see what the name of the props should be
  //filter works on IE.../can I use/ website
  const project = projects.filter(function (project) {
    return project.name === `/${name}`;
  })[0];

  const projectImages = project.projectImages;
  const horizontal = project.horizontal;
  const top = project.top;

  if (project && top && projectImages.length === 3) {
    return (
      <div>
        <NavContainer />
        <Container>
          <ImgThree
            // className="img-3"
            src={require(`../assets/${projectImages[0]}`)}
            alt={props.name}
          ></ImgThree>
          <div className="left-column">
            <ImgOne
              // className="img-1"
              src={require(`../assets/${projectImages[1]}`)}
              alt={props.name}
            ></ImgOne>
          </div>

          <div className="right-column">
            <div className="img-2-container">
              <ImgTwo
                // className="img-2"
                src={require(`../assets/${projectImages[2]}`)}
                alt={props.name}
              ></ImgTwo>
            </div>
          </div>
        </Container>
      </div>
    );
  } else if (project && horizontal && projectImages.length === 3) {
    return (
      <div>
        <NavContainer />
        <Container>
          <div className="left-column">
            <ImgOne
              // className="img-1"
              src={require(`../assets/${projectImages[0]}`)}
              alt={props.name}
            ></ImgOne>
          </div>

          <div className="right-column">
            <div className="img-2-container">
              <ImgTwo
                // className="img-2"
                src={require(`../assets/${projectImages[1]}`)}
                alt={props.name}
              ></ImgTwo>
            </div>
          </div>
          <ImgThree
            // className="img-3"
            src={require(`../assets/${projectImages[2]}`)}
            alt={props.name}
          ></ImgThree>
        </Container>
      </div>
    );
  } else if (project && projectImages.length === 3) {
    return (
      <div>
        <NavContainer />
        <Container>
          <div className="left-column">
            <img
              className="img-1"
              src={require(`../assets/${projectImages[0]}`)}
              alt={props.name}
            ></img>
          </div>
          <div className="right-column">
            <div className="img-2-container">
              <img
                className="img-2"
                src={require(`../assets/${projectImages[1]}`)}
                alt={props.name}
              ></img>
            </div>
            <img
              className="img-3"
              src={require(`../assets/${projectImages[2]}`)}
              alt={props.name}
            ></img>
          </div>
        </Container>
      </div>
    );
  } else if (project && projectImages.length === 2) {
    return (
      <div>
        <NavContainer />
        <Container>
          <div className="left-column">
            <img
              className="img-1"
              src={require(`../assets/${projectImages[0]}`)}
              alt={props.name}
            ></img>
          </div>
          <div className="right-column">
            <div className="img-2-container">
              <img
                className="img-2"
                src={require(`../assets/${projectImages[1]}`)}
                alt={props.name}
              ></img>
            </div>
          </div>
        </Container>
      </div>
    );
  } else {
    return <h1>Project doesn't exist, yo!</h1>;
  }
};

export default DesignVertLeft;
