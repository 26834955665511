import React from "react";
import NavContainer from "../NavContainer/navContainer";
import artProjects from "../../art-images.json";
import "./artDetail.css";

const ArtDetail = (props) => {
  // console.log(props);
  const name = props.match.params.name;
  // console.log(name);
  const artProject = artProjects.filter(function (artProject) {
    return artProject.name === `/${name}`;
  })[0];

  const artProjectImages = artProject.artProjectImages;

  return (
    <div>
      <NavContainer />

      <div className="lg-img-div">
        <img
          id={name}
          className="lg-img"
          src={require(`../assets/${artProjectImages[0]}`)}
          alt={name}
        ></img>
      </div>
    </div>
  );
};

export default ArtDetail;
