import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "./contactModal.css";

const ContactModal = (props) => {
  const { className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const closeBtn = (
    <button className="close" onClick={toggle}>
      &times;
    </button>
  );

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [validate, setValidate] = useState({
    emailState: "",
  });
  const [text, setText] = useState("");

  function validateEmail(e) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(e.target.value)) {
      setValidate({ emailState: 'has-success' });
    } else {
      setValidate({ emailState: 'has-danger' });
    }
  }

  // function sendEmail(e) {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_07lo679",
  //       "template_sa3wxdg",
  //       e.target,
  //       "user_pJZWEKQbrPhVSwJxpLSdb"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         window.location.reload();
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  // }
  function sendEmail(e) {
    e.preventDefault();

    if (validate.emailState !== 'has-success') {
      // Handle the case where the email is not valid
      console.log("Email is not valid. Please correct and try again.");
      alert('Email is not valid, please include a valid email')
    } else {

      emailjs
        .sendForm(
          "service_07lo679",
          "template_sa3wxdg",
          e.target,
          "user_pJZWEKQbrPhVSwJxpLSdb"
        )
        .then(
          (result) => {
            console.log(result.text);
            window.location.reload();
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  }

  return (
    <div>
      <Button color="link" onClick={toggle}>
        CONTACT
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader
          toggle={toggle}
          close={closeBtn}
          style={{
            fontFamily: "'Maven Pro', sans-serif",
          }}
        >
          Send an Email
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={sendEmail}>
            <FormGroup>
              <Label
                for="userEmail"
                style={{
                  fontFamily: "'Maven Pro', sans-serif",
                }}
              >
                Email
              </Label>
              <Input
                style={{
                  fontFamily: "'Maven Pro', sans-serif",
                }}
                value={email}
                valid={validate.emailState === 'has-success'}
                onChange={(event) => {
                  setEmail(event.target.value)
                  validateEmail(event)
                }
                }
                type="email"
                name="email"
                id="userEmail"
                placeholder="your email"
                invalid={validate.emailState === 'has-danger'}
              />
            </FormGroup>
            <FormGroup>
              <Label
                for="userSubj"
                style={{
                  fontFamily: "'Maven Pro', sans-serif",
                }}
              >
                Subject
              </Label>
              <Input
                style={{
                  fontFamily: "'Maven Pro', sans-serif",
                }}
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                type="text"
                name="text"
                id="userSubj"
                placeholder="subject"
              />
            </FormGroup>

            <FormGroup>
              <Label
                for="usertext"
                style={{
                  fontFamily: "'Maven Pro', sans-serif",
                }}
              >
                Text
              </Label>
              <Input
                style={{
                  fontFamily: "'Maven Pro', sans-serif",
                }}
                value={text}
                onChange={(event) => setText(event.target.value)}
                type="textarea"
                name="text"
                id="usertext"
              />
            </FormGroup>
            <Button
              type='submit'
              color="secondary"
              size="lg"
              block

              style={{
                fontFamily: "'Maven Pro', sans-serif",
              }}
              disabled={validate.emailState === 'has-danger'}
            >
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ContactModal;
