import React, { Component } from "react";
import NavContainer from "../components/NavContainer/navContainer";
import FullWImageCard from "../components/FullWImgCard/fullWImageCard.js";
import { disableBodyScroll } from "body-scroll-lock";

class Splash extends Component {
  render() {
    return (
      <div>
        <NavContainer maxToolbar logoWide />

        <FullWImageCard disablescroll={disableBodyScroll(this.targetElement)} />
      </div>
    );
  }
}

export default Splash;
