import React from 'react';
import { motion } from 'framer-motion';
import './imageCard.css';

function ImageCard(props) {
  // console.log(props);
  return (
    <motion.button whileHover={{ scale: 0.9 }} whileTap={{ scale: 0.9 }}>
      <img
        id={props.id}
        className='img-card'
        alt={props.name}
        src={require(`../assets/${props.image}`)}
      />
    </motion.button>
  );
}
export default ImageCard;
