import React, { Component } from "react";
// import Toolbar from "../Toolbar/Toolbar";
import SideDrawer from "../SideDrawer/SideDrawer";
import "../SideDrawer/DrawerToggleButton";
import "../Toolbar/Toolbar.css";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import ContactModal from "../ContactModal/contactModal";
import MLogo from "../assets/logo.png";

class NavContainer extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  xClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let x;
    if (this.state.sideDrawerOpen) {
      x = <x click={this.xClickHandler} />;
    }

    return (
      <div>
        <header
          className={`${this.props.maxToolbar ? "max-toolbar" : "toolbar"}`}
        >
          <nav className="toolbar_navigation">
            <div className="toolbar_toggle-button">
              <DrawerToggleButton click={this.drawerToggleClickHandler} />
            </div>
            <a className="toolbar_logo" href="/">
              <img
                className={`${this.props.logoWide ? "logo-wide" : "m-logo"}`}
                src={MLogo}
                alt="logo"
              ></img>
            </a>
            <div className="spacer"></div>
            <div className="toolbar_nav-items">
              <ul>
                {/* can use router but here using normal anchor tags below */}
                <li>
                  <a className="design" href="/design">
                    DESIGN
                  </a>
                </li>
                <li>
                  <a className="art" href="/art">
                    ART
                  </a>
                </li>
                <li>
                  <a className="info" href="/info">
                    INFO
                  </a>
                </li>
                <li>
                  <ContactModal />
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <SideDrawer
          show={this.state.sideDrawerOpen}
          click={this.xClickHandler}
        />
      </div>
    );
  }
}

export default NavContainer;
