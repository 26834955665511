import React from "react";
import NavContainer from "../NavContainer/navContainer";
import LeftInfoPane from "../assets/infographic1.jpg";
import RightInfoPane from "../assets/infographic2.jpg";
import Container from "../Content_Container/container.js";
import "./info.css";

const Info = (props) => {
  return (
    <div>
      <NavContainer />
      <Container>
        <img className="left-info" src={LeftInfoPane} alt="left-pic" />
        <img className="right-info" src={RightInfoPane} alt="right-pic" />
      </Container>
    </div>
  );
};

export default Info;
