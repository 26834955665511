import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DesignHome from "./pages/designHome";
import Splash from "./pages/splash.js";
import DesignVertLeftLayout from "./components/DesignVertLeft/designVertLeft";
import ArtDetail from "./components/ArtDetail/artDetail";
import Art from "./components/Art/art";
import Info from "./components/Info/info";
import "./App.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/design" component={DesignHome} />
        <Route path="/project/:name" component={DesignVertLeftLayout} />
        <Route path="/art" component={Art} />
        <Route path="/artproject/:name" component={ArtDetail} />
        <Route path="/info" component={Info} />
        <Route exact path="/" component={Splash} />
      </Switch>
    </Router>
  );
}

export default App;
