import React from "react";
import NavContainer from "../components/NavContainer/navContainer";
import DesignMain from "../components/DesignMain/designMain";

function DesignHome() {
  return (
    <div className="homeDiv">
      <NavContainer />
      <DesignMain />
    </div>
  );
}

export default DesignHome;
