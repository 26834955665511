import React from "react";
import ContactModal from "../ContactModal/contactModal";
import "./SideDrawer.css";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <nav className={drawerClasses}>
      <button className="closeX" onClick={props.click}></button>
      <ul>
        <li>
          <a href="/design">DESIGN</a>
        </li>
        <li>
          <a href="/art">ART</a>
        </li>
        <li>
          <a href="/info">INFO</a>
        </li>
        <li>
          <ContactModal />
        </li>
      </ul>
    </nav>
  );
};

export default sideDrawer;
